






import Vue from "vue";
import CreateOrUpdate from "./components/CreateOrUpdate.vue";

export default Vue.extend({
  name: "CourseEdit",
  props: {
    courseId: {
      type: [String, Number],
      required: true
    }
  },
  components: {
    CreateOrUpdate
  }
});
